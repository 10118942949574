import React from 'react';
import Canva from '../components/canva';

export default function Founders() {
  return (
    <Canva
      title="Lost & Founders"
      url="https://www.canva.com/design/DAGbim4tO1c/5k1xI58ff96vPmPpKz-ZeA/view?embed"
    />
  );
}
